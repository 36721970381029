/* eslint-disable no-sequences */
import { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { UpOutlined } from '@ant-design/icons';
import Headroom from 'react-headroom';
import dynamic from 'next/dynamic';

import { getIpadCountries } from 'actions/products';

import { HeadSEO } from 'components/base';
import { MyAccountModal } from 'components/myAccount';
import { MyAccountDrawer } from 'components/myAccount/camperlab-index';
import Breadcrumb from 'components/breadcrumb';
import I18nModal from 'components/i18nModal';
import EventsTwsModal from 'components/eventsTwsModal';
import GiftBagModal from 'components/giftBagModal';
import { MyOrderModal, GlobalEOrdersWidget } from 'components/myOrderModal';
import { useI18n } from 'context/i18n';
import { userContext } from 'context/user';
import { useAppData } from 'context/app';

import { GLOBAL_E_SCRIPTS_ID } from 'utils/constants/system';
// import { TWS_MAGAZINES, TWS_MAGAZINE_LANGS, DEFAULT_MAGAZINE } from 'utils/constants/tws';

import { getLang, getMarket, getCookie, eraseCookie, getIsMobile, COOKIES, setCookie, getUnbxdTranslations } from 'utils/helpers';
import { dataLayerHandleEvent } from 'utils/dataLayers';

import { useIsMobile } from 'hooks';

import SearchTNM from 'components/search';
import { getDomainValue } from 'utils/domain';

import styles from './style.module.css';
import { Button } from 'components/ui/button';
import { useEventListener } from 'hooks/useEventListener';

const Footer = getDomainValue({
  camper: dynamic(() => import('@camper/react-web-components').then((res) => res.ORGANISMS.Footer)),
  nnormal: dynamic(() => import('@nnormal/react-web-components').then((res) => res.ORGANISMS.Footer)),
  camperlab: dynamic(() => import('@camper/react-web-components').then((res) => res.ORGANISMS.Footer)),
});
const TnmComponent = getDomainValue({
  camper: dynamic(() => import('@camper/react-web-components').then((res) => res.ORGANISMS.TnmComponent)),
  nnormal: dynamic(() => import('@nnormal/react-web-components').then((res) => res.ORGANISMS.TnmComponent)),
  camperlab: dynamic(() => import('@camper/react-web-components').then((res) => res.ORGANISMS.TnmComponent)),
});
const Ribbon = dynamic(() => import('components/ribbon'));
const MiniBag = dynamic(() => import('components/miniBag').then((mod) => mod.MiniBag));
const MiniBagCamperlab = dynamic(() => import('components/miniBag/camperlab').then((mod) => mod.MiniBagCamperlab));
const MyAccountWidget = dynamic(() => import('components/myAccount'));
// const MyAccountWidgetCamperlab = dynamic(() => import('components/myAccount/camperlab-index'));
const MembersOnlyModal = dynamic(() => import('components/membersOnlyModal').then((mod) => mod.MembersOnlyModal), { ssr: false });
const StoreDataRow = dynamic(() => import('components/storeDataRow').then((mod) => mod.StoreDataRow));
const CamperLabMyOrders = dynamic(() => import('components/camperlab/camperlab-order-drawer').then((mod) => mod.CamperlabMyOrders));
const AbandonedCartBanner = dynamic(() => import('components/abandonedCartBanner').then((mod) => mod.AbandonedCartBanner));
const NewsletterModal = dynamic(() => import('components/newsletterModal').then((mod) => mod.NewsletterModal));

const widgetBagHidden = ['/compra'];

const myOrderEvents = {
  account: {
    event: 'main_menu',
    event_category: 'menu',
    event_detail_1: 'my account',
    event_detail_2: 'orders',
  },
  tracking: {
    event: 'footer_menu',
    event_category: 'menu',
    event_detail_1: 'shopping on website',
    event_detail_2: 'order tracking',
  },
};

// meter unbxd keys de t y config unbxd para usesNewSearch searchBoxJSON
const SearchBoxJson = (lang, country) => {
  const searchAction = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: `https://www.camper.com/${lang}_${country}`,
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `https://www.camper.com/${lang}_${country}/search?searchTerm={search_term_string}`,
      },
      'query-input': 'required name=search_term_string',
    },
  };
  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(searchAction) }} />;
};

function WithHeadRoom({ children, onPin, onUnpin }) {
  if (getDomainValue({ camper: false, nnormal: false, camperlab: true })) {
    return children;
  }
  return (
    <Headroom onPin={onPin} onUnpin={onUnpin}>
      {children}
    </Headroom>
  );
}

export default function Layout({
  children,
  footerProps,
  withFooter = true,
  withHead = true,
  tnmProps,
  breadcrumbs = true,
  breadcrumbsBottom = false,
  breadcrumbI18n = {},
  breadcrumbFragments = [],
  ribbon = [],
  metatags = null,
  extratags = [],
  camperlab = false,
  scrollArrow = false,
  pageName = null,
  translateSlug = false,
  recycled = false,
  gtmProps,
  popupProps,
}) {
  const { pathname } = useRouter();

  const { locale, countries, t, profileData, appmode, storeData, i18nPage, setStoreData } = useI18n();
  const { newsletter } = useAppData();
  const isMobile = useIsMobile();

  const { global_e, currency } = profileData;
  const [showingI18nModal, setShowingI18nModal] = useState(false);
  const [showingMyAccountModal, setShowingMyAccountModal] = useState(false);
  const [showingMyOrderModal, setShowingMyOrderModal] = useState(false);
  const [showingEventsTwsModal, setShowingEventsTwsModal] = useState(false);
  const [eventsTwsModalType, setEventsTwsModalType] = useState('subscribe');
  const [eventTwsEmail, setEventTwsEmail] = useState('');
  const [showingTnm, setShowingTnm] = useState(false);
  const [scrollArrowVisible, setScrollArrowVisible] = useState(false);
  const [showingRibbon, setShowingRibbon] = useState(true);
  const [userCS, setUserCS] = useState(null);
  const [showGiftBag, setShowGiftBag] = useState(false);

  const [showFooter, setShowFooter] = useState(withFooter);
  const [showHeader, setShowHeader] = useState(withHead);

  const market = getMarket(locale);

  const unbxdTranslations = useMemo(() => getUnbxdTranslations(t), [t]);
  const usesNewSearch = unbxdTranslations !== false && profileData?.config?.unbxd === true;

  const handleChangeOrdersVisibility = (value, origin) => {
    setShowingMyOrderModal(value);

    if (origin) {
      const event = myOrderEvents[origin];

      if (event) {
        dataLayerHandleEvent(event);
      }
    }
  };

  const onClickSignIn = () => {
    setShowingMyAccountModal(true);
  };

  useEffect(() => {
    if (window !== undefined) {
      setUserCS(getCookie(COOKIES.CS_EMPLOYEE_ID));

      window.sendDataLayer = (eventPayload) => {
        dataLayerHandleEvent(eventPayload);
      };

      window.showGiftBagPopUp = () => {
        setShowGiftBag(true);
      };

      window.showEventsTwsModal = (id) => {
        setShowingEventsTwsModal(id);
      };
      window.showOrderPopUp = () => {
        setShowingMyOrderModal(true);
      };

      window.onscroll = () => {
        const { y } = window.document.body.getBoundingClientRect();

        setScrollArrowVisible(y < -400);
        setShowingRibbon(y === 0);
      };

      const urlParams = new URLSearchParams(window.location.search);
      const eventId = urlParams.get('eventId');
      const email = urlParams.get('email');

      if (eventId && email) {
        setShowingEventsTwsModal(eventId);
        setEventsTwsModalType('unsubscribe');
        setEventTwsEmail(email);
      }

      if (eventId) {
        setShowingEventsTwsModal(eventId);
      }

      if (global_e) {
        const [lang, country] = [getLang(locale), getMarket(locale)];
        const data = {
          countryISO: country,
          currencyCode: currency,
          cultureCode: lang,
        };

        const GlobalE_Data = encodeURIComponent(JSON.stringify(data));

        setCookie(COOKIES.GLOBAL_E, GlobalE_Data, 1, COOKIES.DOMAIN);
      }
    }
  }, []);

  const handleOnPinNav = () => {
    const filterNav = document.querySelector('#plp-filter-nav');
    const isDesktop = !(window.innerWidth < 1200);
    const tnmSelector = `.tnm--${isDesktop ? 'desktop' : 'mobile'}`;
    if (filterNav) {
      const tnmHeight = document.querySelector(tnmSelector).offsetHeight;
      filterNav.style.top = `${tnmHeight}px`;
      const filterPanel = document.querySelector('#plp-filter-panel');
      filterPanel.firstChild.style.top = `${filterNav.offsetHeight + tnmHeight}px`;
      filterPanel.firstChild.style.maxHeight = `calc(100vh - ${filterNav.offsetHeight + tnmHeight}px)`;
    }
    const stickyCol = document.querySelector('#sticky-col');
    if (stickyCol) {
      const tnmHeight = document.querySelector(tnmSelector).offsetHeight;
      stickyCol.style.top = `${tnmHeight}px`;
    }
    const landingSticky = document.querySelector('#landing-sticky');
    if (landingSticky) {
      const tnmHeight = document.querySelector(tnmSelector).offsetHeight;
      landingSticky.style.top = `${tnmHeight}px`;
    }
  };
  const handleOnUnpinNav = () => {
    const filterNav = document.querySelector('#plp-filter-nav');
    if (filterNav) {
      filterNav.style.top = '0px';
      const filterPanel = document.querySelector('#plp-filter-panel');
      filterPanel.firstChild.style.top = `${filterNav.offsetHeight}px`;
      filterPanel.firstChild.style.maxHeight = `calc(100vh - ${filterNav.offsetHeight}px)`;
    }
    const stickyCol = document.querySelector('#sticky-col');
    if (stickyCol) {
      stickyCol.style.top = '0px';
    }
    const landingSticky = document.querySelector('#landing-sticky');
    if (landingSticky) {
      landingSticky.style.top = '0px';
    }
  };
  const onTnmShow = () => {
    if (window?.hideAutosuggest) {
      window.hideAutosuggest();
    }

    if (!showingTnm) {
      setShowingTnm(true);
    }
  };

  const onTnmHide = () => {
    setShowingTnm(false);
  };

  const scripts = [];

  if (global_e) {
    scripts.push(
      <script
        key="global-e-external"
        type="text/javascript"
        id="GlobalEScript"
        src={
          process.env.ENVIRONMENT === 'prod' ?
            `https://gepi.global-e.com/includes/js/${GLOBAL_E_SCRIPTS_ID}`
          : `https://intgepi.bglobale.com/includes/js/${GLOBAL_E_SCRIPTS_ID}`
        }
        defer
      />,
      <link
        id="GEPIStyles"
        key="global-e-css"
        rel="stylesheet"
        href={
          process.env.ENVIRONMENT === 'prod' ?
            `https://gepi.global-e.com/includes/css/${GLOBAL_E_SCRIPTS_ID}?countryCode=${getMarket(locale)}`
          : `https://intgepi.bglobale.com/includes/css/${GLOBAL_E_SCRIPTS_ID}?countryCode=${getMarket(locale)}`
        }
      />,
    );
  }

  if (usesNewSearch) {
    scripts.push(SearchBoxJson(...locale.split('_')));
  }

  let ordersWidget = getDomainValue({
    camper: <MyOrderModal locale={locale} visible={showingMyOrderModal} onClose={() => setShowingMyOrderModal(false)} />,
    nnormal: <MyOrderModal locale={locale} visible={showingMyOrderModal} onClose={() => setShowingMyOrderModal(false)} />,
    camperlab: <CamperLabMyOrders visible={showingMyOrderModal} onClose={() => setShowingMyOrderModal(false)} />,
  });

  if (global_e) {
    ordersWidget = (
      <GlobalEOrdersWidget
        onOpen={() => setShowingMyOrderModal(true)}
        visible={showingMyOrderModal}
        onlyModal={true}
        onClose={() => setShowingMyOrderModal(false)}
      />
    );
  }

  const extraTags = [...extratags];
  const isCamperlabInCamper = getDomainValue({ camper: camperlab, nnormal: false, camperlab: false });

  let layoutStyle = undefined;
  if (recycled) {
    layoutStyle = { '--background': 'var(--color-recycled)' };
  } else if (isCamperlabInCamper) {
    layoutStyle = { '--background': 'var(--color-camperlab)', '--font-primary': 'var(--font-camperlab)' };
  }

  let accountWidget = getDomainValue({
    camper: (
      <MyAccountWidget
        dark={isMobile ? false : camperlab}
        onClickYourOrders={() => handleChangeOrdersVisibility(true, 'account')}
        onClickSignIn={onClickSignIn}
      />
    ),
    nnormal: <MyAccountWidget dark={!isMobile} onClickYourOrders={() => handleChangeOrdersVisibility(true, 'account')} onClickSignIn={onClickSignIn} />,
    // camperlab: <MyAccountWidgetCamperlab onClickYourOrders={() => handleChangeOrdersVisibility(true, 'account')} onClickSignIn={onClickSignIn} />,
    camperlab: (
      <Button
        variant="none"
        className="flex items-center text-lg font-bold uppercase lg:text-xs [&_span]:!py-0"
        decoration="underline"
        onPress={() => handleChangeOrdersVisibility(true, 'account')}
      >
        <span className="animated-underline inline-flex items-center !py-0 after:hidden">{t('mi.cuenta', 'mis.pedidos', 'Orders')}</span>
      </Button>
    ),
  });

  if (global_e) {
    accountWidget = (
      <div className="max-md:hidden">
        <GlobalEOrdersWidget onOpen={() => handleChangeOrdersVisibility(true, 'account')} onClose={() => handleChangeOrdersVisibility(false)} />
      </div>
    );
  }

  // logica de calculo de heights de ribbon y tnm
  const ribbonRef = useRef(null);
  const handleRibbonHeight = useCallback((node) => {
    // get node height and set it as a style variable --tnm-height
    if (node) {
      ribbonRef.current = node;
      const ribbonHeight = node.offsetHeight;
      document.body.style.setProperty('--ribbon-height', `${ribbonHeight}px`);
    }
  }, []);

  const headerRef = useRef(null);
  const handleHeaderHeight = useCallback((node) => {
    // get node height and set it as a style variable --tnm-height
    if (node) {
      headerRef.current = node;
      const tnmHeight = node.offsetHeight;

      document.body.style.setProperty('--tnm-height', `${tnmHeight}px`);
    }
  }, []);

  const onWindowResize = useCallback(() => {
    const ribbon = ribbonRef.current;
    const header = headerRef.current;
    if (ribbon) {
      const ribbonHeight = ribbon.offsetHeight;
      document.body.style.setProperty('--ribbon-height', `${ribbonHeight}px`);
    }
    if (header) {
      const tnmHeight = header.offsetHeight;
      document.body.style.setProperty('--tnm-height', `${tnmHeight}px`);
    }
  }, []);

  useEventListener('resize', onWindowResize);

  return (
    <div className={`${styles.layout}`} data-camperlab={isCamperlabInCamper ? 'true' : undefined} style={layoutStyle}>
      <StoreDataRow />

      <HeadSEO
        title={metatags?.pageTitle}
        description={metatags?.metaDescription}
        scripts={scripts}
        pageName={pageName}
        extraTags={extraTags}
        gtmProps={gtmProps}
      />
      {ribbon && ribbon.length > 0 && showHeader && (
        <div ref={handleRibbonHeight}>
          <Ribbon
            show={showingRibbon}
            items={ribbon}
            onClickNewsletter={() => {
              window.openNewsletterModal?.('header', { direct: true });
            }}
            accountWidget={accountWidget}
            camperlab={getDomainValue({
              camper: camperlab,
              nnormal: false,
              camperlab: true,
            })}
          />
        </div>
      )}
      {showHeader && (
        <header ref={handleHeaderHeight} className={`${styles.headerNav} lab:min-h-none lab:sticky lab:top-0 lab:z-30 lab:bg-neutral`}>
          {tnmProps && (
            <WithHeadRoom onPin={handleOnPinNav} onUnpin={handleOnUnpinNav}>
              <TnmComponent
                bagWidget={
                  !widgetBagHidden.includes(pathname) ?
                    getDomainValue({
                      camper: <MiniBag isInSalePath={pathname === '/bolsa'} />,
                      nnormal: <MiniBag isInSalePath={pathname === '/bolsa'} />,
                      camperlab: <MiniBagCamperlab isInSalePath={pathname === '/bolsa'} />,
                    })
                  : null
                }
                bagWidgetModal
                onTnmShow={onTnmShow}
                onTnmHide={onTnmHide}
                showSearch={getDomainValue({ camper: true, camperlab: false, nnormal: true })}
                searcher={getDomainValue({
                  camper: <SearchTNM />,
                  nnormal: <SearchTNM />,
                  camperlab: null,
                })}
                {...tnmProps}
                accountWidget={accountWidget}
                showI18nModal={() => setShowingI18nModal(true)}
                showNewsletterModal={() => {
                  window.openNewsletterModal?.('footer', { direct: true });
                }}
                showOrderTrackingModal={() => setShowingMyOrderModal(true)}
                isStoreMode={Boolean(getCookie(COOKIES.STORE_MODE))}
              />
              {getDomainValue({
                camper: <AbandonedCartBanner />,
              })}
            </WithHeadRoom>
          )}
        </header>
      )}

      <div
        id="layoutContent"
        className={
          getDomainValue({ camper: showingTnm, nnormal: showingTnm, camperlab: false }) ?
            `${styles.backdrop} ${styles.backdropVisible}`
          : `${styles.backdrop} lab:mt-header`
        }
      >
        {breadcrumbs && !breadcrumbsBottom && <Breadcrumb locale={locale} i18nKeys={breadcrumbI18n} camperlab={camperlab} fragments={breadcrumbFragments} />}
        {children}
        {breadcrumbs && breadcrumbsBottom && (
          <div className={styles.breadcrumbsBottom}>
            <Breadcrumb locale={locale} i18nKeys={breadcrumbI18n} camperlab={camperlab} fragments={breadcrumbFragments} pageName={pageName} />
          </div>
        )}
        {showFooter ?
          <>
            {footerProps ?
              <Footer
                {...footerProps}
                showI18nModal={() => setShowingI18nModal(true)}
                showNewsletterModal={() => {
                  window.openNewsletterModal?.('footer', { direct: true });
                }}
                profileData={profileData}
                showOrderTrackingModal={() => {
                  handleChangeOrdersVisibility(true, 'tracking');
                }}
                newsletterTitle={newsletter?.title ?? ''}
                newsletterDescription={newsletter?.text ?? ''}
              />
            : null}
          </>
        : null}
        {getDomainValue({
          camper: true,
          nnormal: true,
          camperlab: false,
        }) &&
          scrollArrow && (
            <button
              className={`${styles.scrollArrow} ${scrollArrowVisible ? '' : 'escondido'} data-[visible=desktop]:max-md:hidden data-[visible=mobile]:md:hidden`}
              data-visible={typeof scrollArrow === 'string' ? scrollArrow : undefined}
              onClick={() => {
                if (window && window.document) {
                  const [body] = document.getElementsByTagName('body');

                  if (body) {
                    body.scrollIntoView({ behavior: 'smooth' });
                  }
                }
              }}
            >
              <UpOutlined />
            </button>
          )}
        {getDomainValue({
          camper: <MyAccountModal locale={locale} visible={showingMyAccountModal} onClose={() => setShowingMyAccountModal(false)} />,
          nnormal: <MyAccountModal locale={locale} visible={showingMyAccountModal} onClose={() => setShowingMyAccountModal(false)} />,
          camperlab: <MyAccountDrawer locale={locale} visible={showingMyAccountModal} onClose={() => setShowingMyAccountModal(false)} />,
        })}
        {ordersWidget}
      </div>
      <GiftBagModal visible={showGiftBag} onClose={() => setShowGiftBag(false)} />
      <I18nModal countries={countries} visible={showingI18nModal} changeVisibility={setShowingI18nModal} translateSlug={translateSlug} />
      <EventsTwsModal
        loading={false}
        id={showingEventsTwsModal === false ? '0' : showingEventsTwsModal}
        modalTwsVisible={showingEventsTwsModal !== false}
        setModalTwsVisible={setShowingEventsTwsModal}
        onFinishOk={() => console.log('ok')}
        type={eventsTwsModalType}
        email={eventTwsEmail}
      />
      <NewsletterModal cmsData={popupProps} camperlab={camperlab} />
      <MembersOnlyModal camperlab={camperlab} />
    </div>
  );
}
